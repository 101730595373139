<template>
  <body class="background">
    
    <div class="logo">
        <img class="title" src="./assets/logo.png">
        <img class="star1" src="./assets/eff_star.png">
        <img class="star2" src="./assets/eff_star.png">
        <img class="star3" src="./assets/eff_star.png">
    </div>
    <div class="timer">
      <div class="number">
        <h1>{{ days }}</h1>
        <h2>DAYS</h2>
        <div class="lighteff"></div>
      </div>
      <div class="number">
        <h1>{{ hours }}</h1>
        <h2>HOURS</h2>
        <div class="lighteff"></div>
      </div>
      <div class="number">
        <h1>{{ minutes }}</h1>
        <h2>MINUTES</h2>
        <div class="lighteff"></div>
      </div>
      <div class="number">
        <h1>{{ seconds }}</h1>
        <h2>SECONDS</h2>
        <div class="lighteff"></div>
      </div>
    </div>
    <div>
      <img class="pinkverse" src="./assets/PINKVERSE.png">
    </div>
    <div>
      <img class="soon" src="./assets/COMINGSOON.png">
    </div>
    <div class="linkgroup">
      <a href="https://www.youtube.com/@pinkverse_universe"><img src="./assets/youtube.png" style="width: 7vw; "></a>
      <a href="https://cafe.naver.com/pinkverse"><img src="./assets/naver.png" style="width: 7vw; "></a>
      <a href="https://twitter.com/pinkverse_twt"><img src="./assets/x.png" style="width: 7vw; "></a>
      <a href="https://www.instagram.com/pinkverse_universe"><img src="./assets/Instagram.png" style="width: 7vw; "></a>
      <a href="https://www.tiktok.com/@pinkverse_universe"><img src="./assets/tiktok.png" style="width: 7vw; "></a>
      <a href="https://www.facebook.com/pinkverseuniverse"><img src="./assets/facebook.png" style="width: 7vw; "></a>
    </div>
    <div class="linkgroup_m">
      <a href="https://www.youtube.com/@pinkverse_universe"><img src="./assets/youtube_m.png" ></a>
      <a href="https://cafe.naver.com/pinkverse"><img src="./assets/naver_m.png"></a>
      <a href="https://twitter.com/pinkverse_twt"><img src="./assets/x_m.png" ></a>
      <a href="https://www.instagram.com/pinkverse_universe"><img src="./assets/Instagram_m.png" ></a>
      <a href="https://www.tiktok.com/@pinkverse_universe"><img src="./assets/tiktok_m.png" ></a>
      <a href="https://www.facebook.com/pinkverseuniverse"><img src="./assets/facebook_m.png" ></a>
    </div>
    <div class="pages">
      <div class="schadule">
        <img :src="require('./assets/web_'+this.pageImgSrc+'.png')" class="web">
        <img :src="require('./assets/web_'+this.pageImgSrc+'_m.png')" class="mobile">
        <div class="langdrop">  
          <div class="top">
            <img src="./assets/kr.png" @click="Setlangauge(0)"/>
            <img src="./assets/jp.png" @click="Setlangauge(1)"/>
            <img src="./assets/en.png" @click="Setlangauge(2)"/>
          </div>
        </div>
        <div class="deco1">
          <img src="./assets/polaroid1.png">
        </div>
        <div class="deco2">
          <img src="./assets/polaroid2.png">
        </div>
        <div class="deco3">
          <img src="./assets/polaroid3.png">
        </div>
        <div class="deco4">
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'App',
  component: {
      dayjs,
  },
  data() {
      return {
        dday: dayjs('2024-3-22 18:00:00'),
        today: dayjs(),
        remain: '',
        timestamp: '',
        days: '00',
        hours:'00',
        minutes:'00',
        seconds:'00',
        itemDisplay:{display:'none'},
        displayIndex:0,
        pageImgSrc: 'kr',
        pageImgURL: '_m',
      }
  },
  methods: {
    countDownTimer () {
      this.timestamp = this.dday.diff(this.today)
      if (this.timestamp > 0) {
        setTimeout(() => {
          this.today = dayjs()
          this.days = this.dday.diff(this.today,'d')
          if(this.days<10)
            this.days = '0'+this.days
          this.hours = this.dday.diff(this.today,'h') - (this.days*24)
          if(this.hours<10)
            this.hours = '0'+this.hours
          this.minutes = this.dday.diff(this.today,'m') - (this.days*24*60+this.hours*60)
          if(this.minutes<10)
            this.minutes = '0'+this.minutes
          this.seconds = this.dday.diff(this.today,'s') - (this.days*24*60*60+this.hours*60*60+this.minutes*60)
          if(this.seconds<10)
            this.seconds = '0'+this.seconds
          this.countDownTimer()
        }, 1000)
      }
    },
    Setlangauge(index) {
      console.debug(this.pageImgSrc)
      if(index == 0)
      {
        this.pageImgSrc='kr'
      }
      else if(index == 1)
      {
        this.pageImgSrc='jp'
      }
      else if(index == 2)
      {
        this.pageImgSrc='en'
      }
    }
  },
  created () {        
    this.today = dayjs()
    this.timestamp = this.dday.diff(this.today)
    if (this.timestamp > 0) {
    this.days = this.dday.diff(this.today,'d')
      if(this.days<10)
        this.days = '0'+this.days
      this.hours = this.dday.diff(this.today,'h') - (this.days*24)
      if(this.hours<10)
        this.hours = '0'+this.hours
      this.minutes = this.dday.diff(this.today,'m') - (this.days*24*60+this.hours*60)
      if(this.minutes<10)
        this.minutes = '0'+this.minutes
      this.seconds = this.dday.diff(this.today,'s') - (this.days*24*60*60+this.hours*60*60+this.minutes*60)
      if(this.seconds<10)
        this.seconds = '0'+this.seconds
      this.countDownTimer()
    }
  }
}
</script>

<style>
  @import "./assets/css/mstyle.css";
</style>
